import React from 'react'
import Layout from '../components/Layout'

const About = () => {
  return (
    <Layout>
      <div>
        <h1>À propos de Ātman Yoga</h1>

<h3>Retrouver une belle énergie de vie et l'harmonie</h3>
<p>«Le but principal de notre recherche devrait être de nous libérer des tensions, des soucis, des distractions et de la perte d’énergie qu’amène une vie sans harmonie afin que nous puissions, à tout instant , nous tourner intérieurement vers la source réelle de la connaissance, de la force et de la paix, qui se tient profondément enfouie en nous.» Swami Veetamohananda</p>
<p>La pratique du yoga dans un cours collectif permet à chacun.e d’avancer sur le chemin du yoga et de devenir autonome pour une pratique individuelle.</p>
<p>Pendant les séances de yoga la force et la souplesse du corps sont travaillées grâce aux Asanas, ce sont les postures.</p>
<p>La pratique de techniques respiratoires permet de mieux maitriser la respiration, c’est le Pranayama.</p>
<p>Ces pratiques permettent de trouver une nouvelle énergie de vie et d'avancer vers l'état de Yoga.</p>
<p>Dans les Yogas Sutras, Patanjali dit: «L’objectif du yoga est d’arrêter les fluctuations automatiques du mental.» Sutra I.2 Calmer le mental , se recentrer, méditer afin que ce ne soit plus notre  mental qui nous guide.</p>
      </div>
    </Layout>
  );
}
 
export default About